<template>
  <v-main>
    <nav-bar />
    <template slot:top>
      <v-subheader>
        <h1>Comptes Pandaloc</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{ 'items-per-page-options': [50] }"
      dense
    >
      <template v-slot:item.user="{ item }">
        <v-btn small class="info" @click="editRenter(item.userUid)">
          {{ item.user }}
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        target="_blank"
        @click="editItem(item.uid)"
      >
        mdi-pencil
      </v-icon>
    </template>

    </v-data-table>
  </v-main>
</template>

<script>
import axios from "axios";
import navBar from "../components/navBar.vue";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      headers: [
        { text: "Id", value: "id", sortable: true },
        { text: "IBAN Swan", value: "swanIban", sortable: true },
        { text: "Titulaire", value: "name", sortable: true},
        { text: "Type", value: "type", sortable: true},
        { text: "Propriètaire", value: "user", sortable: true},
        { text: "Statut Pandaloc", value: "status", sortable: true},
        { text: "Statut Swan", value: "swanVerificationStatus", sortable: true},
        { text: "State", value: "state", sortable: true},
        { text: "Solde", value: "balance", sortable: true},
        { text: "Dépôt encaissé", value: "transferredReserve", sortable: true},
        { text: "Date de création", value: "creationDate", sortable: true},
        { text: "Date d'activation", value: "activationDate", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      searchFields: [
        {
          text: "Dans toutes les colonnes",
          value: "swanIban,name,type,user,status,swanVerificationStatus",
        },
        { text: "IBAN Swan", value: "swanIban"},
        { text: "Titulaire", value: "name"},
        { text: "Type", value: "type"},
        { text: "Propriètaire", value: "user"},
        { text: "Statut Pandaloc", value: "status"},
        { text: "Statut Swan", value: "swanVerificationStatus"},
      ],
      searchField: "swanIban,name,type,user,status,swanVerificationStatus",
      items: [],
      itemsLength: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
      query: "",
    };
  },

  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },

  methods: {
    getItems() {
      axios
        .get(process.env.VUE_APP_APIURL + "/paymentApps", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort:
              this.options.sortBy[0] +
              " " +
              (this.options.sortDesc[0] ? "DESC" : "ASC"),
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.items = response.data.map((item) =>
            Object.assign(
              item,
              { creationDate: new Date(item.creationDate).toLocaleString() },
              {activationDate: item.activationDate != null ? new Date(item.activationDate).toLocaleString() : ''},
              {
                status: {
                  1: "1 (brouillon)",
                  2: "2 (en attente de validation Swan)",
                  3: "3 (actif)",
                }[item.status]
              },
              { type: item.type === 1 ? '1 individu' : '2 société' },
              {state: item.state.step},
            )
          );
        });
      axios
        .get(process.env.VUE_APP_APIURL + "/paymentApps", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            count: 1,
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.itemsLength = eval(response.data[0].count);
        });
    },

    search() {
      this.options.page = 1;
      this.getItems();
    },

    editRenter(uid) {
      open(
        this.$router.resolve({ name: "user", params: { userUid: uid } }).href,
        "_blank"
      );
    },

    editItem (uid) {
      open(this.$router.resolve({name: 'paymentApp', params: {paymentAppUid: uid}}).href, '_blank');
    },
  },
};
</script>