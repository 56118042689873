<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-subheader>
        <h1>{{ firstname }} {{ lastname }}</h1>
      </v-subheader>
      <v-list dense nav>
        <v-list-item
          v-for="(link, i) in links"
          :key="i"
          :to="link.path"
          link
        >
          <v-list-item-icon>
            <v-icon>
              {{ link.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ link.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-3">
          <v-btn
            block
            class="info" 
            @click="logout"
          >
            logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      color="blue accent-4"
      app
      dense
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Pandadmin</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    data:() => ({
      firstname: localStorage.getItem('firstname'),
      lastname: localStorage.getItem('lastname'),
      drawer: false,
      links: [
        {title: 'Logements', path: "/properties", icon: 'mdi-sign-real-estate'},
        {title: 'Logements supprimé', path: "/deletedProperties", icon: 'mdi-text-box-remove-outline'},
        {title: 'Dossiers', path: "/rentalFiles", icon: 'mdi-folder'},
        {title: 'Candidatures', path: "/applications", icon: "mdi-account-box-multiple"},
        {title: 'Utilisateurs', path: "/users", icon: 'mdi-account'},
        {title: 'Comptes Pandaloc', path: "/paymentApps", icon: 'mdi-account-cash'},
        {title: 'Loyers', path: "/paymentAppsFlows", icon: 'mdi-domain'},
        {title: 'Transactions', path: "/transactions", icon: 'mdi-bank-transfer'},
        {title: 'Réservations', path: "/bookings", icon: 'mdi-key-chain-variant'},
        // {title: 'Listes déroulantes', path: "/content", icon: 'mdi-table-of-contents'},
      ]
    }),
    methods: {
      logout(){
        localStorage.clear()
        this.$router.push('/login')
      }
    }
  }
</script>

<style scoped>
  h1 {
    font-size: 1.25em;
  }
</style>
